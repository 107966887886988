( function( $, window, document, undefined ) {

	var hotspots = $('.hotspots').find('.hotspots__item');
	var count = 0;

	if ( hotspots.length ) {
		hotspots.each(function() {

			var leftPos = $(this).data('position-x');
			var topPos = $(this).data('position-y');
			var title = $(this).data('title');

			if ( topPos > 60 ) {
				$(this).addClass('tt-top');
			}

			if ( leftPos > 90 ) {
				$(this).addClass('tt-left');
			}

			$(this).css('left', leftPos + '%');
			$(this).css('top', topPos + '%');
			$(this).append('<div class="fore"></div><div class="tt"><div class="tt__inner">' + title + '</div></div>')
		});
	}

} )( jQuery, window, document );
