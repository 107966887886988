( function( $, window, document, undefined ) {

	const disableBodyScroll = bodyScrollLock.disableBodyScroll;
	const enableBodyScroll = bodyScrollLock.enableBodyScroll;

	const hamburger = $('.hamburger');
	const nav = $('.nav-wrap');
	const navLink = $('.nav-wrap').find('a[href*=\\#]');

	hamburger.on('click', function() 
	{
		if ( ! nav.hasClass('is-open') ) 
		{
			openMenu();
		} else 
		{
			closeMenu();
		}
	});

	 navLink.on('click', function() {
	 	closeMenu();
	 });

	function openMenu() 
	{
		disableBodyScroll(nav);
		nav.addClass('is-open');
		hamburger.addClass('is-active');
	}

	function closeMenu() 
	{
		enableBodyScroll(nav);
		nav.removeClass('is-open');
		hamburger.removeClass('is-active');
	}


	
} )( jQuery, window, document );