( function( $, window, document, undefined ) {

	var cta = $('.hero__cta');
	var caption = $('.slick-slide__caption').find('span');
	var caption_bottom = caption.offset().top + caption.outerHeight();
	var overlay = $('.hero').find('.overlay');
	var hero = $('.hero');
	var footer = $('.site-footer');
	var scrollButton = $('.back-to-top');

	$(window).on('scroll load', throttle( function() {

		var scrolled = $(this).scrollTop();
			if ( scrolled >= 60 && !cta.hasClass('shrink') ) 
		{
			cta.addClass('shrink');
		} 
		else if ( scrolled < 60 && cta.hasClass('shrink') ) 
		{
			cta.removeClass('shrink');
		}

		if ( checkVisibility(hero) ) {
			scrollButton.removeClass('is-visible');
		} else {
			scrollButton.addClass('is-visible');
		}

		if ( checkVisibility(footer) ) {
			cta.addClass('hide')
		} else {
			cta.removeClass('hide');
		}

	}, 50 ));

	$(window).on('scroll load',function() {

		var scroll_top = $(this).scrollTop();

		caption.css('transform', 'translateY(-' + scroll_top * 0.3 + 'px)');
		caption.css('opacity', 1 - ( scroll_top / caption_bottom ) );
		overlay.css('opacity', 1 - ( scroll_top / caption_bottom ) );

	});

	function checkVisibility(elem) {

		var topOfElement = elem.offset().top;
	    var bottomOfElement = elem.offset().top + elem.outerHeight();
	    var bottomOfScreen = $(window).scrollTop() + $(window).innerHeight();
	    var topOfScreen = $(window).scrollTop();

	    if ((bottomOfScreen > topOfElement) && (topOfScreen < bottomOfElement)){
	        return true;
	    } else {
	        return false;
	    }
	}
	
} )( jQuery, window, document );
